@charset "utf-8";

// ======== All Device
body{
  letter-spacing: 1px;
}

.main_visual{
  width:100%;
  position:relative;
  background-size:cover;
  margin: 0;
  .hlogo{
    background:rgba(#F0ECE1,0.9);
    width: 180px;
    height: 290px;
    position: absolute;
    top: 0px;
    left: 100px;
    z-index: 2;
    margin: 0;
    padding: 60px 0 0 54px;
    box-sizing: border-box;
    object{
      width: 70px;
    }
  }
  @media ( max-width : 1000px ) {
    .hlogo{
      left: 0px;
    }
  }
  .catch{
    letter-spacing: 3px;
    color: #FFF;
    font-size: 22px;
    font-size: 2.2rem;
    position:absolute;
    top:50%;
    left:0;
    text-align:center;
    width:100%;
    margin:-10px 0 0;
    opacity:0;
    transition:.6s .3s;
    transform: translateY(10px);
    &.animated{
      opacity:1.0;
      transform:none;
    }
    img{
      width: 100%;
    }
  }
}

.topSec01{
  @media ( min-width : 769px ) {
    margin-top: 143px !important;
  }
  width: 100%;
  height: 400px;
  margin: 0;
  background:url(/wp-content/uploads/bg_section01.jpg) center no-repeat;
  background-size:cover;
  text-align: center;
  opacity: 0;
  transition:.6s .3s;
  transform: translateY(20px);
  &.animated{
    opacity:1.0;
    transform:none;
  }
  p{
    letter-spacing: 3px;
    color: #FFF;
    font-size: 22px;
    font-size: 2.2rem;
    line-height: 400px;
  }
}

.topSec02{
  background: #B79255;
  margin: 0;
  .more a{
    border: 1px solid #111;
    color: #111;
    transition: .3s;
    &:hover{
      background: #111;
      color: #B79255;
      &:after{
        border-top: 1px solid #B79255;
        border-right: 1px solid #B79255;
      }
    }
  }
  .add_arrow:after{
    border-top: 1px solid #111;
    border-right: 1px solid #111;
  }
  .box01{
    margin: 0;
    .left{
      height: 400px;
      background:url(/wp-content/uploads/top_img001.jpg) center no-repeat;
      background-size:cover;
      opacity: 0;
      transition:.6s .3s;
      transform: translateY(20px);
      &.animated{
        opacity:1.0;
        transform:none;
      }
    }
    .right{
      padding: 100px 40px 0;
      opacity: 0;
      transition:.6s .3s;
      transform: translateY(-20px);
      &.animated{
        opacity:1.0;
        transform:none;
      }
      .tit{
        font-weight: bold;
        font-size: 22px;
        font-size: 2.2rem;
      }
      .txt{
        margin: 0 0 30px;
      }
    }
  }
  .box02{
    margin: 0;
    .left{
      padding: 50px 40px 0;
      opacity: 0;
      transition:.6s .3s;
      transform: translateY(-20px);
      @media ( max-width : 1280px ) {
        padding: 37px 37px 0;
      }
      @media ( max-width : 1024px ) {
        padding: 17px 37px 0;
      }
      @media ( max-width : 768px ) {
        padding: 10px 20px 0;
      }
      &.animated{
        opacity:1.0;
        transform:none;
      }
      .tit{
        font-weight: bold;
        font-size: 22px;
        font-size: 2.2rem;
      }
      .txt{
        margin: 0 0 30px;
      }
    }
    .right{
      height: 400px;
      background:url(/wp-content/uploads/top_img002.jpg) center no-repeat;
      background-size:cover;
      opacity: 0;
      transition:.6s .3s;
      transform: translateY(20px);
      &.animated{
        opacity:1.0;
        transform:none;
      }
    }
  }
}

.topSec03{
  width: 100%;
  height: 400px;
  margin: 0;
  padding: 150px 0 0;
  color: #FFF;
  box-sizing: border-box;
  background:url(/wp-content/uploads/bg_section02.jpg) center no-repeat;
  background-size:cover;
  text-align: center;
  opacity: 0;
  transition:.6s .3s;
  transform: translateY(20px);
  &.animated{
    opacity:1.0;
    transform:none;
  }
  .st{
    font-weight: bold;
    font-size: 22px;
    font-size: 2.2rem;
    letter-spacing: 3px;
  }
}

.topSec04{
  padding: 60px 0 40px;
}

// main.naka_box{
//   color: #111;
//   padding: 0;
// }

// h1.title_bg{
  // display:block;
  // margin-top: 0;
  // height: 200px;
  // line-height: 200px;
  // text-align:center;
  // font-size: 28px;
  // font-size: 2.8rem;
  // color: #FFF;
  // &:before,&:after {content: none;}
// }
// h1#company.title_bg{
//   background:url(/wp-content/uploads/h1_company.jpg) center no-repeat;
//   background-size:cover;
// }
// h1#kaiseki.title_bg{
//   background:url(/wp-content/uploads/h1_kaiseki.jpg) center no-repeat;
//   background-size:cover;
// }
// h1#blog.title_bg{
//   background:url(/wp-content/uploads/h1_blog.jpg) center no-repeat;
//   background-size:cover;
// }
// h1#japanese.title_bg{
//   background:url(/wp-content/uploads/h1_japanese.jpg) center no-repeat;
//   background-size:cover;
// }
// h1#english.title_bg{
//   background:url(/wp-content/uploads/h1_english.jpg) center no-repeat;
//   background-size:cover;
// }
// h1#chinese1.title_bg{
//   background:url(/wp-content/uploads/h1_chinese1.jpg) center no-repeat;
//   background-size:cover;
// }
// h1#chinese2.title_bg{
//   background:url(/wp-content/uploads/h1_chinese2.jpg) center no-repeat;
//   background-size:cover;
// }
// h1#contact.title_bg{
//   background:url(/wp-content/uploads/h1_policy.jpg) center no-repeat;
//   background-size:cover;
// }
// h1#policy.title_bg{
//   background:url(/wp-content/uploads/h1_policy.jpg) center no-repeat;
//   background-size:cover;
// }

.mb20{margin-bottom:20px !important;}
.mb30{margin-bottom:30px !important;}
.mb40{margin-bottom:40px !important;}
.pt20{padding-top:20px !important;}
.fwb{font-weight:bold;}

.catch_txt{
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  font-size: 2.4rem;
}

.tit_naka{
    color: #B02722;
    border-bottom: 4px double #B02722;
    padding: 0 0 5px;
    font-size: 30px;
    font-size: 3.0rem;
    margin: 0 0 1em;
}

// .naka{
  // padding:0 10px;
  // h2{
    // text-align:left;
    // margin:0 0 20px;
    // padding:0 0 8px;
    // font-size:24px;
    // font-size:2.4rem;
    // position:relative;
    // color: #111;
    // width: 100%;
    // border-bottom: 4px double #111;
  // }
  // h2:before{
  //   content:none;
  // }
  // h2:after{
  //   content:none;
  // }
// }

// .naka h1 {
  // display: table;
  // table-layout: fixed;
  // margin: 0 auto 1.5em;
  // position: relative;
  // border: 0;
  // padding: 0 !important;
  // word-wrap: break-word;
  // font-weight: bold;
  // @include fontsize(28);
  // color: $color_h2_text;
  // @media ( max-width : 600px ) {
  //   //width: 80%;
  // }
  // @if $wow == true {
  //   opacity: 0;
  //   transition: .5s;
  // }
  // a {
  //   text-decoration: none;
  //   &:hover {
  //     text-decoration: underline;
  //   }
  // }
  // &:after,
  // &:before {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   top: 50% !important;
  //   transform: translateY(-50%);
  //   height: 2px;
  //   background: #000;
  //   @if $wow == true {
  //     opacity: 0;
  //     width: 0;
  //     transition: 1s .3s;
  //   }
  //   @else {
  //     width:30px;
  //   }
  // }
  // &:before {
  //   right: calc(100% + 15px);
  // }
  // &:after {
  //   left: calc(100% + 15px);
  // }
// }

.stit{
  font-size: 16px;
  font-size: 1.6rem;
  color: #FFF;
  text-align: center;
  background: #009F6F;
  padding: 6px 0;
  margin: 0 0 10px;
}

.ml10{
  margin: 0 0 0 10px;
}

.column_main{
  padding: 0 0 100px;
}
.column_sub{
  padding: 0 0 100px;
}
.nayami_box{
  padding: 20px;
  border: 1px solid #00316c;
  box-sizing: border-box;
}
.number_list{
  list-style: decimal;
  font-size: 18px;
  font-size: 1.8rem;
}
.step h3{
  margin: 0;
}
.step h3:after{
  content: none;
}

// ======== Tablet & PC
@media ( max-width : 768px ) {
  .main_visual{
    margin: 0;
    header{
      position: absolute;
      top: -1px;
    }
    header .global_nav .menu_icon a:before, header .global_nav .menu_icon a:after,
    header .global_nav .menu_icon a span,
    header .global_nav .menu_icon a.close:not(.fix):before,
    header .global_nav .menu_icon a.close:not(.fix):after{
      background: #FFF;
    }
    header .global_nav .menu_icon{
      top: 50px;
    }
    .catch{
      margin:-20px 0 0;
    }
  }
  main.naka_box{
    padding: 0;
  }
  footer{
    .container{
      padding: 0;
    }
  }
  .ml10{
    margin: 0;
  }
  .column_main{
    padding: 0 0 40px;
  }
  .column_sub{
    padding: 0;
  }
  .topSec01{
    height: 300px;
    padding: 0 20px;
    p{
      line-height: 300px;
    }
  }
  .topSec02{
    .box01{
      .left{
        height: 300px;
      }
      .left2{
        height: 300px;
        background:url(/wp-content/uploads/top_img002.jpg) center no-repeat;
        background-size:cover;
        opacity: 0;
        transition:.6s .3s;
        transform: translateY(20px);
        &.animated{
          opacity:1.0;
          transform:none;
        }
      }
      .right{
        height: auto;
        padding: 40px 20px;
        transform: translateY(20px);
      }
    }
  }
  .topSec03{
    height: 300px;
    padding: 110px 20px 0;
  }
} // @media

// ======== Mobile
@media ( max-width : 480px ) {
  .main_visual{
    margin: 0;
    box-sizing: border-box;
    .hlogo{
      background:rgba(#F0ECE1,0.9);
      width: 140px;
      height: 230px;
      position: absolute;
      top: 0px;
      left: 0;
      z-index: 2;
      margin: 0;
      padding: 50px 0 0 40px;
      box-sizing: border-box;
      object{
        width: 50px;
      }
    }
  }
  .topSec03{
    height: 300px;
    padding: 100px 20px 0;
  }
  .topSec04{
    padding: 60px 0 0;
  }
} // @media

// ======== PC
@media ( min-width : 1024px ) {

} // @media
