@charset "utf-8";

.step .row,
.step-h .col {
  position: relative;
  padding: 1em;
  background: #E5EAF0;
}

.step .row:not(:last-child):after,
.step-h .col:not(:last-child):after,
.step-h .row.continue .col:last-child:after
{
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: 0;
  background: url(/wp-content/uploads/arrow_down.svg) center center no-repeat;
  background-size: auto 100%;
}

@media ( max-width : 767px ) {

.step .row:not(:last-child),
.step-h .col:not(:last-child),
.step-h .row.continue .col:last-child
{
  margin-bottom: 18%;
  &:after {
    padding-top: 20%;
  }
}

.step-h .row.continue {
  margin-bottom: 0;
}

}

@media ( min-width : 768px ) {

.step {
  .row {
    display: table;
    table-layout: fixed;
    width: 100%;
    padding: 2em;
    .col {
      float: none;
      display: table-cell;
      vertical-align: middle;
      &:not(:first-child) {
        padding-left: 2%;
      }
    }
    &:not(:last-child) {
      margin-bottom: 80px;
      &:after {
        top: 100%;
        padding-top: 60px;
        margin: 10px 0;
      }
    }
  }
  h3:last-child {
    margin-bottom: 0;
    @include fontsize(16);
    border: none;
    &:before,
    &:after {
      content: none;
    }
  }
}

.step-h {
  &.gutters .col {
    margin: 0;
  }
  .col {
    padding: 0;
    background: none;
  }
  .col:not(:last-child) {
    position: relative;
    margin-right: 4%;
    &:after {
      display: none;
    }
    figure span {
      display: inline-block;
      position: relative;
      width: 100%;
      &:after {
        content: '';
        display: block;
        width: 28px;
        height: 100%;
        position: absolute;
        right: -30px;
        top: 0;
        background: url(/wp-content/uploads/arrow_right.svg) center center no-repeat;
        background-size: contain;
      }
    }
  }
  .span_3 {
    width: 22%;
  }
  .span_4 {
    width: 30.66666666%;
  }
  .span_6 {
    width: 48%;
  }
  .col:not(:last-child) {
    // padding-right: 2%;
  }
}

}

@media ( min-width : 1024px ) {

.step-h {
  .col:not(:last-child) {
    figure span:after {
      right: -34px;
    }
  }
}

}
