@charset "utf-8";

@import "color";

// ======== fonts
  $base_font_size: 14; // px
  // ヒラギノ明朝
  $HiraMin: "Times New Roman", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "メイリオ", Meiryo, serif;

  // $HiraMinB: "Roboto Slab", Garamond, "Times New Roman", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "メイリオ", Meiryo, serif;

  // ヒラギノ角ゴ
  $Hiragino: Verdana, Roboto, "Droid Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  // メイリオ
  $Meiryo: Verdana, Roboto, "Droid Sans", "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", sans-serif;

  $base_font_family: $HiraMin;


// ======== common(chung)
  $design_concept: 'material'; // デザインコンセプト material or flat(design concept material or flat)
  $container: 1000; // containerの最大幅(chiều rộng lớn nhất của container)
  $header_break_point: 769; // ヘッダーのブレイクポイント（ロゴ/Gナビの調整が難しい場合1000px等に設定する）(breakpoint của header nếu logo/G nabi khó điều chỉnh thì thiết lập khoảng 1000px)
  $fixed_header: false; // 固定ヘッダー true or false(header cố định true or false)
  $header_type: 'bottom'; // メインビジュアルに対するヘッダーの表示位置 'top' or 'bottom'(vị trí hiển thị của header đối với main visual 'top' or 'bottom')
  $navi_type_sp: 'y'; // スマホヘッダナビの表示軸　'x' or 'y'(cột hiển thị của header navi SP)

  // svg形式のロゴのサイズをラフを元に正確に入れる(thêm size của logo cách bằng svg chính xác trong rough)
  // svg内部の数値とCSS上で扱う数値は違うので要注意(cần chú ý trị số trong svg và trị số sử dụng trong CSS khác nhau )
  $logo_width: 100; // ロゴの横幅(chiều rộng của logo)
  $logo_height: 40; // ロゴの縦幅(chiều rộng và chiều dọc của logo)
  $logo_sp_width: 100; // スマホ時のロゴ横幅　（横320に対して、ハンバーガーメニューを加えても余裕のある値にしておく）(chiều rộng logo khi trên SP(với chiều rộng 320 thì mặc dùng thay đổi hamburger menu thì thiết lập trị số có dư )

  $header_height: 80; // ヘッダー全体の高さ(chiều cao của toàn bộ header)
  $header_logo_type: 'A'; // ヘッダのロゴタイプ　'A' or 'B' or 'C'(golo type của header 'A' or 'B' or 'C' )

  $header_alpha: true; // ヘッダの高さを無くし透過させるか　true or false(chiều cao của header không có làm cho trong suốt true or false)

  // ヘッダの検索表示タイプ(type hiển thị tìm kiếm của header)
  // 'A'・・・アイコンによる開閉式(cách đóng mở tùy vào icon)
  // 'B'・・・テキストボックスが”右上”に常に見えているタイプ(text box là type có thể thường thấy ở "phía trên bên phải")
  // 'C'・・・テキストボックスが”Gナビと同じ並びで”常に見えているタイプ(text box là type có thể thường thấy sắp xép giống nhau với "G navi")
  $header_search: 'A';

  // ヘッダの検索デザインタイプ(type design tìm kiếm của header)
  // 'A'・・・シンプルな検索ボックス(box tìm kiếm đơn giản)
  // 'B'・・・アイコンタイプ（左）(type icon(bên trái))
  // 'C'・・・アイコンタイプ（右）(type icon(bên phải))
  $header_search_design: 'A';

  $header_menu_type: 'A'; // メニュータイプ　'A' or 'B' or 'C'(menu type 'A' or 'B' or 'C' )

  $footer_type: 'A'; // フッタのデザインタイプ　'A' or 'B'(type design footer 'A' or 'B')
  $pagetop_type: 'A'; // ページトップのデザインタイプ　'A' or 'B' or 'C'(type design của top page 'A' or 'B' or 'C')
  $side_column: 'right'; // 下層カラムの位置　'left' or 'right'(vị trí của column sub page)


// ======== main_visual
  // スライドの種類
  // true ・・・ fade
  // false ・・・ slide
  $main_slide_fade: true;

  $main_slide_arrows: false; //ページャー矢印の有無　true or false
  $main_slide_dots: false; //ページャードット表示の有無　true or false

  $main_slide_width: 1920; //スライド画像のwidth(width của ảnh slide)
  $main_slide_height: 960; //スライド画像のheight(height của ảnh slide)

  $main_slide_correct: 1.5; // スマホの際、高さが縮まりやすいので補正を入れる（倍率）(khi trên SP thì chiều cao dễ bị co lại nên hãy thêm vào(số lần phóng đại)
  $main_slide_break_point: 600; // 補正を入れるブレイクポイントを指定(bổ sung vào chỉ định breakpoint)

  // スライド画像の見せ方(layout ảnh slide)
  // 'A'・・・常に画像全体が見えているスライド。　縦横比率を維持しながらリサイズされる(slide thường có thể nhìn thấy toàn hình ảnh, duy trì tỉ lệ dọc ngang và resize.)
  // 'B'・・・全画面スライド。常にウィンドウの高さ＝メインビジュアルの高さとなる。(slide full màn hình. chiều cao window thông thường=chiều cao của main visual)
  // 'C'・・・ブレイクポイント～メイン画像の最大サイズまでは高さが最大サイズで固定となるスライド。ラフの時点でメイン画像の高さが絞られているケースに適している。(breakpoint~)...................................
  // 'D'・・・サムネイル画像付きスライド。スライド枚数の入力が必要。オプションは３行先を参照。(slide thêm ảnh thumbnail.cần thêm vào số lượng slide. option thì tham khảo 3 dòng trên)
  $main_slide_type: 'C';

  // スライドD用の表示オプション(option hiển thị dùng slide D)
  $main_slide_type_count: 3; //枚数(số ảnh)
  $main_slide_type_thumbnail_position: 'right'; //サムネイル位置(vị trí của thumbnail)

  // スライド上のコピー画像(ảnh copy trên slide)
  // スライドに応じてFadeするか true or false(Fade ứng với slide?)
  // trueの場合、　.main_visual_slide li　と　.main_visual_copy li の数は(trường hợp true thì số lượng .main_visual_slide li　 và .main_visual_copy li)
  // 一致している必要がある(cần thống nhất)
  $main_slide_copy_sync: true; // スライドに応じてFadeするか true or false(Fade ứng với slide?true or false)

  $main_slide_copy_width: 170; //スライド上のコピー画像のwidth(width của ảnh copy trên slide)
  $main_slide_copy_height: 606; //スライド上のコピー画像のheight(height của ảnh copy trên slide)


// ======== button
  // 共通(chung)
  $button_minwidth: 0px; // 最低幅(chiều rộng nhỏ nhất)
  $button_arrow: 'rigt'; // <span>で囲ったときの矢印の位置 'left' or 'right'(vị trí mũi tên khi bao quanh bằng <span> 'left' or 'right')

  // デフォルトボタン(default button)
  $style_button_border: 0; // ボーダースタイル(border style)
  $style_button_border_radius: $style_button_border_radius; // 角丸(góc tròn)

  // カラー反転ボタン
  $style_button_inverse_border: 0; // ボーダースタイル
  $style_button_inverse_border_radius: $style_button_border_radius; // 角丸(góc tròn)

// ======== slider
  $slide_type: 'A' !default; // スライドのタイプ(type slide)

  // Type A
  $shape_slider_arrow: 'square' !default; // 前後切り替えボタンの形状 'square' or 'circle'(hình dạng button trước sau thay đổi 'square' or 'circle')

  // Type B

  // コンテンツスライダー(contents slider)
  $contents_slider_image_width: 450px; // 画像の幅(chiều rộng của ảnh)
  $contents_slider_image_height: 300px; // 画像の高さ(chiều cao của ảnh)
  $contents_slider_caption_minheight: 40px; // キャプションの高さの最小値(giá trị chiều cao nhỏ nhất của caption)
  $style_contents_slider_caption_border: 1px dashed #999; // キャプションの左右のボーダースタイル(border style trái phải của caption)
  $contents_slider_arrow_width: 10px; // 前後切り替えボタンの幅(chiều ngang button trước sau thay đổi)

// ======== information
  $style_news_border: 1px solid rgba(0,0,0,0.2); // 各お知らせ間のボーダースタイル(border style giữa các お知らせ)

// ======== parallax
  $parallax_height_pc:(); $parallax_height_sp:(); $parallax_img:(); // 触らない(không đụng vào)

  // 以下3行を設置個数分コピーして編集(thiết lập 3 dòng dưới đây, copy chỉnh sửa 3 dòng dưới đây)
  $parallax_height_pc: append($parallax_height_pc, 340px); // パララックスの高さ(PC)(chiều cao parallax(PC))
  $parallax_height_sp: append($parallax_height_sp, 450px); // パララックスの高さ(SP)(chiều cao parallax(SP))
  $parallax_img: append($parallax_img, url(/wp-content/uploads/parallax_bg.jpg)); // パララックス画像のパス(đường dẫn của ảnh parallax)


// 設定ここまで(thiết lập đến đây)
// ========================================================================================

@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/mixin/_base.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/mixin/_flexbox.scss";


/* version */

@import "vendor/common.css";

@import "common";
@import "header";
@import "footer";

@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/slider/_contents.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/slider/_typeA.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/slider/_typeB.scss";

@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/parts/_before_after.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/parts/_bread.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/parts/_button.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/parts/_feature.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/parts/_grid.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/parts/_guide.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/parts/_inquiry.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/parts/_movie.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/parts/_news.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/parts/_parallax.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/parts/_question_answer.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/parts/_shopinfo.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/parts/_sns.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/parts/_sp_image_cols.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/parts/_step.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/parts/_table.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/parts/_wide_image.scss";

@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/core/_headline.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/core/_main_slide.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/core/_search.scss";

@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/post/_th02_post02.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/post/_th02_post03.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/post/_th03_bnr.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/post/_th03_post01.scss";

@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/other/_biz_calendar.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/other/_calendar.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/other/_effect.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/other/_menu.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/other/_pagetop.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/other/_th01_bnr.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/other/_th02_archive.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/other/_th02_bnr.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/other/_th02_single.scss";
@import "C:/xampp/htdocs/projects/src/ryozanpaku.co.jp/assets/sass/other/_th04_bnr.scss";


@import "utility";

@import "custom";
