@charset "utf-8";

// 管理用　ここの値は基本的に触らない
$color_primary: #083388 !default;
$color_text: #1b1b1b !default;
$design_concept: 'material' !default;
$container: 1000 !default;


// 設定ファイルここから
$th04_image: '/wp-content/uploads/_th04_bnr_bg.jpg';
$th04_height: 1100; // PCでの高さを決め打ち（単位： px）
$th04_block_width: 500; // ブロックの最大幅（単位： px）
$th04_block_bg_color: rgba(255,255,255,0.9); // ブロックの背景色
$th04_block_text_color: $color_text; // ブロックの文字色
$th04_block_count: 2; // ブロックの数　2 or 3
$th04_block_control: 'right'; // ひとつ目のブロックの配置

.th04_bnr {
  h2{
    margin:0 auto;
    padding:10px 0 10px;
  }
  .th04_bnr_first{
    z-index:2;
  }
  .th04_bnr_second{
    z-index:1;
  }
  h2,
  h3 {
    &:before,
    &:after {
      content: none;
    }
  }
  .parallax_1 {
    height: auto;
    padding: 3% 0;
    background-image: url($th04_image);
    background-size: auto 100%;
  }
  .th04_bnr_block {
    overflow: hidden;
    background: $th04_block_bg_color;
    color: $th04_block_text_color;
    margin: 0 2% 2%;
    padding: 1em;
    @if $th04_block_count == 2 {
      &.th04_bnr_third {
        display: none;
      }
    }
    ul {
      padding: 0;
      margin: 0;
      &.row_inline {
        li {
          margin-left: 2%;
          &:nth-child(3n+1) {
            margin-left: 0;
          }
        }
      }
    }
    li {
      list-style: none;
      padding: 0;
      img {
        width: 100%;
      }
    }
  }
}
@media ( min-width : 768px ) {
  .th04_bnr {
    .parallax_1 {
      position: relative;
      height: $th04_height+px;
      padding: 0;
      background-size: cover;
    }
    .container {
      position: relative;
      height: 100%;
    }
    .th04_bnr_block {
      position: absolute;
      width: ($th04_block_width/$container)*100+%;
      margin: auto;
      padding: 1em;
      @if $th04_block_count == 2 {
        &.th04_bnr_first {
          top: 7%;
        }
        &.th04_bnr_second {
          bottom: 7%;
        }
      } @else if $th04_block_count == 3 {
        &.th04_bnr_first {
          top: 7%;
        }
        &.th04_bnr_second {
          top: 50%;
          transform: translateY(-50%);
        }
        &.th04_bnr_third {
          bottom: 7%;
        }
      }
      @if $th04_block_control == 'left' {
        &.th04_bnr_first {
          left: 2%;
        }
        &.th04_bnr_second {
          right: 2%;
        }
      } @else if $th04_block_control == 'right' {
        &.th04_bnr_first {
          right: 2%;
        }
        &.th04_bnr_second {
          left: 2%;
        }
        &.th04_bnr_third {
          right: 2%;
        }
      }
    }
  }
}
@media ( min-width : 1200px ) {
  .th04_bnr {
    .th04_bnr_block {
      @if $th04_block_control == 'left' {
        &.th04_bnr_first {
          left: 0 - (580/$container)*100+%;
          right: 0;
        }
        &.th04_bnr_second {
          left: (680/$container)*100+%;
          right: 0;
        }
      } @else if $th04_block_control == 'right' {
        &.th04_bnr_first {
          left: (580/$container)*100+%;
          right: 0;
        }
        &.th04_bnr_second {
          left: 0 - (680/$container)*100+%;
          right: 0;
        }
        &.th04_bnr_third {
          left: (680/$container)*100+%;
          right: 0;
        }
      }
    }
  }
}
