@charset "utf-8";

// 管理用　ここの値は基本的に触らない
$color_primary: #083388 !default;
$color_text: #1b1b1b !default;
$design_concept: 'material' !default;
$container: 1200 !default;


// 設定ファイルここから
$th03_bnr_break_point: $container; //コンテナのmax-width
$th03_bnr_img_width: 960; //画像のwidth
$th03_bnr_img_height: 420; //画像のheight
$th03_bnr_bg: rgba($color_primary, .5);
$th03_bnr_text_color: #fff;
$th03_bnr_distance: 15; //マイナスすると上下逆になる

.th03_bnr {
  h2{
    width: 100%;
    color: #B02722;
    border-bottom: 4px double #B02722;
    padding: 0 0 5px;
    font-size: 30px;
    font-size: 3.0rem;
    margin: 0 0 1em;
  }
  h2:before,h2:after{
    content:none;
  }
  .th03_bnr_text {
    @media(max-width: 479px){
      padding: 35px;
    }
    padding: 80px;
    color: $th03_bnr_text_color;
    @media(max-width: 767px){
      background-size: cover;
    }
    p{
      color: #1f1f1f;
    }
  }
  .th03_bnr_button {
    &.more {
      a {
        color: #1b1b1b;
        border: 1px solid #1b1b1b;
        transition: .3s;
        @include fontsize(14);
        &:hover{
          background: #1b1b1b;
          color: #fff;
					transition: .3s;
        }
        &:after {
          content: none;
        }
      }
    }
  }
  .reverse {
    .th03_bnr_text {
      left: 8%;
      padding: 80px;
      @media(max-width: 479px){
        padding: 35px;
      }
    }
  }
}

@media ( min-width : 768px ) {
  .th03_bnr {
    position: relative;
    +.th03_bnr {
      margin-top: 5%;
    }
    .th03_bnr_img {
      position: absolute;
      left: 49%;
      right: auto;
      top: 0;
      margin: auto;
      overflow: visible;
      padding-top: ($th03_bnr_img_height / $th03_bnr_break_point)*100 + %;
      >* {
        position: absolute;
        top: 0;
        right: -100%;
        left: 0;
        text-align: left;
      }
    }
    .th03_bnr_text {
      position: relative;
      width: 60%;
      left: -60px;
      @media (max-width: 900px) {
        width: 80%;
      }
      overflow: hidden;
      $val: $th03_bnr_img_height+px;
      min-height: calc(#{$val} * 1 );
      background-size: auto;
      p:not(.th03_bnr_button) {
        line-height: 2;
        margin-bottom: 2em;
      }
    }
    .th03_bnr_button {

    }
    .reverse {
      .th03_bnr_img {
        left: auto;
        right: 45%;
        >* {
          right: 0;
          left: -100%;
          text-align: right;
        }
      }

    }
    &.wow {
      .th03_bnr_img {
        opacity: 0;
        transition: 1s .5s;
      }
      .th03_bnr_text {
        opacity: 0;
        transition: 1s .5s;
      }
    }
    &:not(.wow),
    &.animated {
      .th03_bnr_img {
        transform: translate(0, (0 - $th03_bnr_distance)+px);
        opacity: 1;
      }
      .th03_bnr_text {
        transform: translate(0, $th03_bnr_distance+px);
        opacity: 1;
      }
    }
  }
}
