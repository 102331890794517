@charset "utf-8";

$btnColor01: $color_action;

/*
 * ボタン矢印
 */
.add_arrow {
  display: block;
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 10%;
    margin: auto;
    width: 6px;
    height: 6px;
    border-top: 1px solid $btnColor01;
    border-right: 1px solid $btnColor01;
    transform: translate(0, -50%) rotate(45deg);
    transition: .2s;
  }
  &:hover {
    &:after {
      right: 7%;
      border-color: #FFF;
    }
  }
}

.white .add_arrow {
  &:after {
    border-color: #FFF;
  }

  &:hover {
    &:after {border-color: $btnColor01;}
  }
}//white .add_arrow

/*
 * moreボタン雛形
 */
.more {
  display: inline-block;
  overflow: hidden;
  margin: auto;
  a {
    display: inline-block;
    letter-spacing: normal;
    border: 1px solid $btnColor01;
    color: $btnColor01;
    padding: 1em 3.5em 1em 3.5em;
    text-decoration: none;
    @include fontsize(14);
    @media ( min-width : 768px ) {
      @include fontsize(16);
    }
  }//a

  &.white a {
    border-color: #FFF;
    color: #FFF;
  }

}//more

/*
 * ボタンエフェクト
 */
@media ( min-width : 768px ) {
  .effect01 {
    overflow: hidden;
    position: relative;

    a {
      position: relative;
      z-index: 1;

      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 140%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: -1;
        background: $btnColor01;
        opacity: 1;
        transform: translateX(-120%) skewX(15deg);
        transition: transform .3s;
      }//before

      &:hover {
        color: #FFF;

        &:before {
          transform: translateX(-15%) skewX(15deg);
        }//before
      }//hover
    }//a

    &.white a {
      position: relative;

      &:before {
        background: #FFF;
      }

      &:hover {color: $btnColor01;}
    }//white a
  }//effect01
}//media
