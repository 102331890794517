@charset "utf-8";

// 管理用　ここの値は基本的に触らない
$color_primary: #083388 !default;
$color_text: #1b1b1b !default;
$design_concept: 'material' !default;
$container: 1200 !default;


// 設定ファイルここから
$th02_bnr_type: 'container'; // コンテナwidth container or wide

$th02_bnr_background: rgba($color_primary, .7);
$th02_bnr_color: #fff;
$th02_bnr_more_color: #35cce9;

$th02_bnr_hover_effect: 'border'; //'none' or 'border'
$th02_bnr_line_distance: 5;
$th02_bnr_line_weight: 1;
$th02_bnr_line_color: #fff;

.th02_bnr {
  .container {
    @if $th02_bnr_type == 'container' {
      max-width: $container+px;
    } @else {
      max-width: 100%;
    }
  }
  img {
    max-width: 100%;
  }
  .th02_bnr_text {
    padding: 1.5em;
    background: $th02_bnr_background;
    color: $th02_bnr_color;
    h2,
    h3 {
      color: $th02_bnr_color;
      &:before,
      &:after {
        content: none;
      }
    }
    .th02_bnr_button {
      &.more {
        a {
          position: relative;
          color: #FFF;
          border: 1px solid #FFF;
          &:after {
            border-color: #FFF;
          }
        }
      }
    }
  }
  .th02_bnr_text_inner {
    overflow: hidden;
    display: inline;
  }
}

@media (min-width: 768px) {
  .th02_bnr {
    .tile {
      >.col {
        position: relative;
        @if $th02_bnr_hover_effect == 'border' {
          &:before,
          &:after {
            content: '';
            display: block;
            position: absolute;
            width: 0;
            height: $th02_bnr_line_weight+px;
            background: $th02_bnr_line_color;
            z-index: 1;
            transition: .5s;
          }
          &:before {
            top: $th02_bnr_line_distance+px;
            left: $th02_bnr_line_distance+px;
          }
          &:after {
            bottom: $th02_bnr_line_distance+px;
            right: $th02_bnr_line_distance+px;
          }
          &:hover {
            &:before,
            &:after {
              $val: ($th02_bnr_line_distance * 2)+px;
              width: calc(100% - #{$val});
            }
          }
        }
        .row {
          display: flex;
          height: 100%;
          width: 100%;
          @if $th02_bnr_hover_effect == 'border' {
            position: relative;
            &:before,
            &:after {
              content: '';
              display: block;
              position: absolute;
              $val: ($th02_bnr_line_distance * 2)+px;
              height: 0;
              width: $th02_bnr_line_weight+px;
              background: $th02_bnr_line_color;
              z-index: 1;
              transition: .5s;
            }
            &:before {
              left: $th02_bnr_line_distance+px;
              bottom: $th02_bnr_line_distance+px;
            }
            &:after {
              right: $th02_bnr_line_distance+px;
              top: $th02_bnr_line_distance+px;
            }
            &:hover {
              &:before,
              &:after {
                $val: ($th02_bnr_line_distance * 2)+px;
                height: calc(100% - #{$val});
              }
            }
          }
          &.reverse {
            flex-direction : row-reverse;
          }
        }
      }
    }
    .th02_bnr_text {
      position: relative;
      transition: .3s;
      &:hover {
        @if $design_concept == 'material' {
          box-shadow:
            15px -5px 30px -18px rgba(0, 0, 0, 0.3),
            -15px -5px 30px -18px rgba(0, 0, 0, 0.3);
        }
      }
      p:not(.th02_bnr_button) {
        margin-bottom: 2em;
        line-height: 1.8;
      }
      .th02_bnr_button {
        position: absolute;
        bottom: 1.5em;
        left: 1.5em;
      }
    }
    .th02_bnr_img {
      position: relative;
      overflow: hidden;
      height: 100%;
      img {
        position: absolute;
        top: -100%;
        bottom: -100%;
        left: -100%;
        right: -100%;
        margin: auto;
        height: 100%;
        max-width: none;
      }
    }
  }
}

@media (min-width: 1000px) {
  .th02_bnr {
    .tile {
      >.col {
        @if $th02_bnr_type == 'container' {
          $val: $container+px;
          min-height: calc(#{$val} / 4)!important;
        } @else {
          min-height: 25vw!important;
        }
        position: relative;
      }
    }
  }
}
