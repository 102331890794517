@charset "utf-8";

$wow: false;
.parallax_h1{
  height:200px;
}
h1:not(.title) {
  position: relative;
  @include fontsize(30);
  font-weight: normal;
  padding:0 6%;
  color: $color_h1_text;
  letter-spacing: .05em;
  margin: 0 0 .5em;
  padding: 2.5em 0;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: cover;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 0px;
    background: $color_h1_text;
    transition: 1s .5s;
  }
  &.wow {
    @if $wow == true {
      opacity: 0;
      transition: .5s;
      &:after {
        width: 0;
      }
      &.animated {
        opacity: 1;
        &:after {
          width: 200px;
        }
      }
    }
  }
}
h2{
  display: block;
  // display: table;
  table-layout: fixed;
  margin: 0 auto 1em;
  padding: 0 0 .1em;
  position: relative;
  border: 0;
  word-wrap: break-word;
  font-weight: normal;
  text-align: left;
  border-bottom: 4px double #111;
  @include fontsize(27);
  color: $color_h2_text;
  @media ( max-width : 600px ) {
    //width: 80%;
  }
  @if $wow == true {
    opacity: 0;
    transition: .5s;
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  // &:after,
  // &:before {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   height: 2px;
  //   background: #000;
  //   @if $wow == true {
  //     opacity: 0;
  //     width: 0;
  //     transition: 1s .3s;
  //   }
  //   @else {
  //     width:30px;
  //   }
  // }
  &:before {
    right: calc(100% + 15px);
  }
  &:after {
    left: calc(100% + 15px);
  }
}
@if $wow == true {
  h2 {
    &.animated {
      opacity: 1;
      &:after,
      &:before {
        opacity: 1;
        width: 50vw;
      }
    }
  }
}

h3 {
  margin: 0 0 1.5em;
  padding-bottom: 1em;
  color: $color_h3_text;
  font-weight: normal;
  @include fontsize(19);
  position: relative;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 40px;
    height: 1px;
    border-bottom: 2px solid $color_h3_text;
  }
}
h4 {
  margin: 0 0 1.5em;
  // padding-left: 15px;
  @include fontsize(16);
  position: relative;
  color: $color_h4_text;
  font-weight: normal;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  // &:after {
  //   content: '';
  //   position: absolute;
  //   bottom: 0;
  //   top: 0;
  //   left: 0;
  //   display: block;
  //   width: 6px;
  //   height: 1px;
  //   margin: auto;
  //   border-bottom: 2px solid $color_h4_text;
  // }
}
.widget_wrap {
  .widget_header {
    @include fontsize(16);
    color: #FFF;
    padding: .7em 0 .7em 1em;
    background: rgba($color_primary, 1.0);
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 767px) {
  h1:not(.title) {
    @include fontsize(30);
    &:after {
      height: 0px;
    }
  }
  h2 {
    @include fontsize(18);
    &:after,
    &:before {
      height: 1px;
    }
  }
  h3 {
    @include fontsize(16);
  }
  h4 {
    @include fontsize(14);
  }
  .widget_wrap {
    .widget_header {
      @include fontsize(14);
    }
  }
}
