@charset "utf-8"; // 画像のくり抜き normal or circle
// circleの場合は画像も正方形である必要がある
$th01_bnr_type: 'normal'; //section name "top_menu_area"
.top_menu_area {
  color: #fff;
  padding-top: 50px;
  padding-bottom: 5.4%;
  margin-bottom:0;
  background: url("/wp-content/uploads/top_menu_area_bg.jpg");
  h2 {
    color: #fff;
    &:after,
    &:before {
      background: #fff;
    }
  }
}
.th01_bnr {
  @media(max-width: 1200px) {
    padding-left: 3%;
    padding-right: 3%;
  }
  .col {
    @media(max-width: 768px) {
      width: 95%;
margin: 3% auto!important;
max-width: 580px;
float: none;
    }
  }
  .th01_bnr_wrap {
    &.span_3 {
      @media(min-width: 768px) and (max-width: 1000px) {
        width: 50%;
        &:nth-child(3) {
          clear: both;
        }
      }
    }
  }
  .gutters {
    .th01_bnr_wrap {
      &.span_3 {
        @media(min-width: 768px) and (max-width: 1000px) {
          width: 49%;
          &:nth-child(3) {
            clear: both;
            margin-left: 0;
          }
        }
      }
    }
  }
  .th01_bnr_wrap {
    position: relative;
    > *:not(.th01_bnr_content) {
      padding: 1em;
    }
    > h3 {
      text-align: center;
      border-bottom: 0;
      span {
        display: block;
      }
    }
    > p {
      line-height: 2;
    }
    &:hover {
      @if $design_concept == 'material' {
        box-shadow: 15px -5px 30px -18px rgba(0, 0, 0, 0.3), -15px -5px 30px -18px rgba(0, 0, 0, 0.3);
      }
    }
    &:hover {
      .th01_bnr_content {
        h3 {
          transform: translateY(-145%);
          transition: transform 0.3s;
          z-index: 1;
        }
        .th01_bnr_button {
          transform: translate(-50%, -50% );
          opacity: 1;
        }
        &:before {
          background-color: rgba(130, 105, 28, 0.502);
          transform: rotateZ(45deg) scale(2, 3) translate(0, 0);
        }
      }
    }
  }
  .th01_bnr_content {
    h3 {
      position: absolute;
      top: 57%;
      transform: translateY(-57%);
      width: 100%;
      text-align: center;
      color: #fff;
      border-bottom: 0;
      &:after {
        content: none;
      }
      @include fontsize(24);
      @media (max-width: 767px) {
         @include fontsize(16);
      }
    }
    position: relative;
    overflow: hidden;
    z-index: 2;
    @if $th01_bnr_type == 'circle' {
      border-radius: 50%;
    }
    > img {
      width: 100%;
      display: table;
      margin-left: auto;
      margin-right: auto;
    }
    .th01_bnr_button {
      position: absolute;
      top: 58%;
      left: 50%;
      transform: translate(-50%, 0% );
      margin: auto;
      z-index: 3;
      opacity: 0;
      text-align: center;
      transition: 0.3s;
      &.effect01.effect01:before {
        background-color: rgba(130, 105, 28, 1);
      }
      a {
        $arrow_color: #fff;
        display: inline-block;
        letter-spacing: normal;
        border: 1px solid $arrow_color;
        color: $arrow_color;
        //font-family: 'Arial Narrow', arial;
        text-decoration: none;
        @include fontsize(14);
        padding: 0.4em 1em 0.4em 0.4em;
        @media (min-width: 768px) {
           @include fontsize(16);
          padding: 0.83em 2.6em 0.83em 2.5em;
        }
        &:after {
          border-color: $arrow_color;
        }
      }
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 1;
      background: rgba($color_primary, .3);
      transform: rotateZ(45deg) scale(2, 3) translate(75%, 0);
      transition: 0.3s;
    }
  }
}

.th01_bnr_content{
  &:after{
    content:"";
    display: block;
    border: 1px solid rgba(255,255,255,.8);
    height: 96%;
    height: -webkit-calc(100% - 20px) ;
    height: calc(100% - 20px) ;
    width: 96%;
    width: -webkit-calc(100% - 20px) ;
    width: calc(100% - 20px) ;
    position: absolute;
    top: 10px;
    left: 10px;
    box-sizing: border-box;
    z-index: 2;
  }
}
